<template>
  <div v-if="wGRPmoddedTable && Object.keys(wGRPmoddedTable).length !== 0">
    <h6 class="mt-2">wGRP's</h6>
    <div class="table-responsive wgrp-table__wrapper mb-0">
      <table id="wgrps-table" class="table table-sm small">
        <thead>
          <tr>
            <th>{{ $t('table.commercial') }}</th>
            <th>
              <abbr :title="`${$t('table.duration')} (${$t('table.sec')})`">{{ $t('table.dur') }}</abbr>
            </th>
            <th>
              <abbr :title="`${$t('table.fact')} (${$t('table.plan')})`">{{ $t('booking.total') }}</abbr>
            </th>
            <th>
              <abbr :title="`${$t('table.fact')} (${$t('table.plan')})`">{{ $t('channelModal.prime') }}</abbr>
            </th>
            <th>
              <abbr :title="`${$t('table.fact')} (${$t('table.plan')})`">{{ $t('channelModal.offPrime') }}</abbr>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(comm, cIndex) in wGRPmoddedTable.commercials">
            <tr :key="cIndex" class="font-weight-bold" :class="isThemeHeader === 'true' ? 'bg-dark-lighter' : 'bg-light'">
              <td class="border-top-w-2px">
                [{{ cIndex }}] {{ comm.totals.name }}<br />
                {{ $t('table.placed') }}<br />
                {{ isCurrentUserChannelManager ? '' : $t('table.price') }}
              </td>
              <td class="border-top-w-2px">
                {{ comm.totals.duration }}<br />
                {{ comm.totals.totalPlacedDuration }}
              </td>
              <td class="ws-nowrap border-top-w-2px">
                {{ comm.totals.totalCommFactWgrp | toFixedAndSpace }} ({{ comm.totals.totalCommPlanWgrp | toFixedAndSpace }})<br />
                {{ comm.totals.totalSpotsCount }}<br />
                <template v-if="!isCurrentUserChannelManager">{{ comm.totals.totalCommPrice | toFixedAndSpace }}</template>
              </td>
              <td class="ws-nowrap border-top-w-2px">
                {{ comm.totals.totalCommFactPrimeWgrp | toFixedAndSpace }} ({{ comm.totals.totalCommPlanPrimeWgrp | toFixedAndSpace }})<br />
                {{ comm.totals.spot_count_prime }}<br />
                <template v-if="!isCurrentUserChannelManager">{{ comm.totals.totalCommPrimePrice | toFixedAndSpace }}</template>
              </td>
              <td class="ws-nowrap border-top-w-2px">
                {{ comm.totals.totalCommFactOffPrimeWgrp | toFixedAndSpace }} ({{ comm.totals.totalCommPlanOffPrimeWgrp | toFixedAndSpace }})<br />
                {{ comm.totals.spot_count_off_prime }}<br />
                <template v-if="!isCurrentUserChannelManager">{{ comm.totals.totalCommOffPrimePrice | toFixedAndSpace }}</template>
              </td>
            </tr>
            <template v-for="(set, index) in comm.settings">
              <tr :key="cIndex + '_' + index">
                <td colspan="2">
                  {{ set.setting.date_start_at | convertDate }}-{{ set.setting.date_end_at | convertDate }}<br />
                  {{ isCurrentUserChannelManager ? '' : $t('table.price') }}
                </td>
                <td class="ws-nowrap">
                  {{ set.factGrp | toFixedAndSpace }} ({{ set.setting.grp | toFixedAndSpace }})<br />
                  <template v-if="!isCurrentUserChannelManager">{{ set.totalPrice | toFixedAndSpace }}</template>
                </td>
                <td class="ws-nowrap">
                  {{ set.primeGrp | toFixedAndSpace }} ({{ set.primeWgrp | toFixedAndSpace }})<br />
                  <template v-if="!isCurrentUserChannelManager">{{ set.primePrice | toFixedAndSpace }}</template>
                </td>
                <td class="ws-nowrap">
                  {{ set.ofPrimeGrp | toFixedAndSpace }} ({{ set.ofPrimeWgrp | toFixedAndSpace }})<br />
                  <template v-if="!isCurrentUserChannelManager">{{ set.ofPrimePrice | toFixedAndSpace }}</template>
                </td>
              </tr>
            </template>
          </template>
          <tr class="font-weight-bold ws-nowrap">
            <td>
              {{ $t('booking.total') }}<br />
              {{ $t('table.placed') }}<br />
              {{ $t('table.plannedCost') }}<br />
              {{ isCurrentUserChannelManager ? '' : $t('table.totalPrice') }}
            </td>
            <td>
              {{ wGRPmoddedTable.totals.duration }}<br />
              {{ wGRPmoddedTable.totals.totalPlacedDuration }}
            </td>
            <td>
              {{ wGRPmoddedTable.totals.totalFactWgrp | toFixedAndSpace }} ({{ wGRPmoddedTable.totals.totalPlanWgrp | toFixedAndSpace }})<br />
              {{ wGRPmoddedTable.totals.totalSpotsCount }}<br />
              {{ wGRPmoddedTable.commitment_budget | toFixedAndSpace }} <br />
              <template v-if="!isCurrentUserChannelManager">{{ wGRPmoddedTable.totals.totalPrice | toFixedAndSpace }}</template>
            </td>
            <td>
              {{ wGRPmoddedTable.totals.totalFactPrimeWgrp | toFixedAndSpace }} ({{ wGRPmoddedTable.totals.totalPlanPrimeWgrp | toFixedAndSpace }})<br />
              {{ wGRPmoddedTable.totals.totalSpotsCountPrime }}<br />
              <br />
              <template v-if="!isCurrentUserChannelManager">{{ wGRPmoddedTable.totals.totalPrimePrice | toFixedAndSpace }}</template>
            </td>
            <td>
              {{ wGRPmoddedTable.totals.totalFactOffPrimeWgrp | toFixedAndSpace }} ({{ wGRPmoddedTable.totals.totalPlanOffPrimeWgrp | toFixedAndSpace }})<br />
              {{ wGRPmoddedTable.totals.totalSpotsCountOffPrime }}<br />
              <br />
              <template v-if="!isCurrentUserChannelManager">{{ wGRPmoddedTable.totals.totalOffPrimePrice | toFixedAndSpace }}</template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import convertDate from '@/filters/convertDate';
import toFixedAndSpace from '@/filters/toFixedAndSpace';

export default {
  name: 'WgrpTable',
  filters: {
    convertDate,
    toFixedAndSpace,
  },
  props: {
    commercialItems: { type: Array, required: true },
  },
  data() {
    return {
      wGRPmoddedTable: {},
    };
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      wgrpTable: 'getMediaPlanWGRPTable',
      isCurrentUserChannelManager: 'getIsCurrentUserChannelManager',
    }),
  },
  watch: {
    wgrpTable() {
      this.calculateTableTotals(); // run table data update after each wgrp table request
    },
  },
  created() {
    this.calculateTableTotals(); // Need for first render time
  },
  destroyed() {},
  methods: {
    calculateTableTotals() {
      this.wGRPmoddedTable = {};
      if (this.wgrpTable && Object.keys(this.wgrpTable).length !== 0) {
        this.wGRPmoddedTable.commercials = this.wgrpTable.wgrps;
        this.wGRPmoddedTable.totals = {};
        this.wGRPmoddedTable.commitment_budget = this.wgrpTable.commitment_budget;
        //init general totals for the end of table
        let totalPlanWgrp = 0;
        let totalFactWgrp = 0;

        let totalPlanPrimeWgrp = 0;
        let totalFactPrimeWgrp = 0;

        let totalPlanOffPrimeWgrp = 0;
        let totalFactOffPrimeWgrp = 0;

        let totalPrice = 0;
        let totalPrimePrice = 0;
        let totalOffPrimePrice = 0;

        let totalPlacedDuration = 0;
        let totalSpotsCount = 0;
        let totalSpotsCountPrime = 0;
        let totalSpotsCountOffPrime = 0;

        // need to count totals for commercial, general totals, commercial name and duration

        for (let comm in this.wgrpTable.wgrps) {
          //inside commercial
          //init vars for commercial
          let totalCommPlanWgrp = 0;
          let totalCommFactWgrp = 0;

          let totalCommPlanPrimeWgrp = 0;
          let totalCommFactPrimeWgrp = 0;

          let totalCommPlanOffPrimeWgrp = 0;
          let totalCommFactOffPrimeWgrp = 0;

          let totalCommPrice = 0;
          let totalCommPrimePrice = 0;
          let totalCommOffPrimePrice = 0;
          const commercial = this.commercialItems?.find((e) => e.id === +comm);
          if (commercial) {
            this.wGRPmoddedTable.commercials[comm].totals.name = commercial.name;
            this.wGRPmoddedTable.commercials[comm].totals.duration = commercial.duration;
          }
          for (let setting in this.wgrpTable.wgrps[comm].settings) {
            // inside its settings
            this.wGRPmoddedTable.commercials[comm].settings[setting].factGrp =
              this.wgrpTable.wgrps[comm].settings[setting].primeGrp + this.wgrpTable.wgrps[comm].settings[setting].ofPrimeGrp;
            // count general fact (plan) wgrp's
            totalCommFactWgrp += this.wgrpTable.wgrps[comm].settings[setting].primeGrp + this.wgrpTable.wgrps[comm].settings[setting].ofPrimeGrp;
            totalCommPlanWgrp += +this.wgrpTable.wgrps[comm].settings[setting].setting.grp;
            // count prime
            totalCommFactPrimeWgrp += +this.wgrpTable.wgrps[comm].settings[setting].primeGrp;
            totalCommPlanPrimeWgrp += +this.wgrpTable.wgrps[comm].settings[setting].primeWgrp;
            //count offprime
            totalCommFactOffPrimeWgrp += +this.wgrpTable.wgrps[comm].settings[setting].ofPrimeGrp;
            totalCommPlanOffPrimeWgrp += +this.wgrpTable.wgrps[comm].settings[setting].ofPrimeWgrp;
            // count price
            totalCommPrice += +this.wgrpTable.wgrps[comm].settings[setting].totalPrice || 0;
            // count prime price
            totalCommPrimePrice += this.wgrpTable.wgrps[comm].settings[setting].primePrice || 0;
            // count offprime price
            totalCommOffPrimePrice += +this.wgrpTable.wgrps[comm].settings[setting].ofPrimePrice || 0;

            //count general
            totalFactWgrp += this.wgrpTable.wgrps[comm].settings[setting].primeGrp + this.wgrpTable.wgrps[comm].settings[setting].ofPrimeGrp;
            totalPlanWgrp += +this.wgrpTable.wgrps[comm].settings[setting].setting.grp;
            totalFactPrimeWgrp += +this.wgrpTable.wgrps[comm].settings[setting].primeGrp;
            totalPlanPrimeWgrp += +this.wgrpTable.wgrps[comm].settings[setting].primeWgrp;
            totalFactOffPrimeWgrp += +this.wgrpTable.wgrps[comm].settings[setting].ofPrimeGrp;
            totalPlanOffPrimeWgrp += +this.wgrpTable.wgrps[comm].settings[setting].ofPrimeWgrp;
            totalPrice += +this.wgrpTable.wgrps[comm].settings[setting].totalPrice || 0;
            totalPrimePrice += this.wgrpTable.wgrps[comm].settings[setting].primePrice || 0;
            totalOffPrimePrice += +this.wgrpTable.wgrps[comm].settings[setting].ofPrimePrice || 0;
          }
          //commercial totals
          this.wGRPmoddedTable.commercials[comm].totals.totalCommFactWgrp = totalCommFactWgrp;
          this.wGRPmoddedTable.commercials[comm].totals.totalCommPlanWgrp = totalCommPlanWgrp;
          this.wGRPmoddedTable.commercials[comm].totals.totalCommFactPrimeWgrp = totalCommFactPrimeWgrp;
          this.wGRPmoddedTable.commercials[comm].totals.totalCommPlanPrimeWgrp = totalCommPlanPrimeWgrp;
          this.wGRPmoddedTable.commercials[comm].totals.totalCommFactOffPrimeWgrp = totalCommFactOffPrimeWgrp;
          this.wGRPmoddedTable.commercials[comm].totals.totalCommPlanOffPrimeWgrp = totalCommPlanOffPrimeWgrp;
          this.wGRPmoddedTable.commercials[comm].totals.totalCommPrice = totalCommPrice;
          this.wGRPmoddedTable.commercials[comm].totals.totalCommPrimePrice = totalCommPrimePrice;
          this.wGRPmoddedTable.commercials[comm].totals.totalCommOffPrimePrice = totalCommOffPrimePrice;
          //spots totals
          this.wGRPmoddedTable.commercials[comm].totals.totalPlacedDuration =
            this.wGRPmoddedTable.commercials[comm].totals.commercial_duration_prime +
            this.wGRPmoddedTable.commercials[comm].totals.commercial_duration_off_prime;
          this.wGRPmoddedTable.commercials[comm].totals.totalSpotsCount =
            this.wGRPmoddedTable.commercials[comm].totals.spot_count_prime + this.wGRPmoddedTable.commercials[comm].totals.spot_count_off_prime;
          //count general
          totalPlacedDuration += +this.wGRPmoddedTable.commercials[comm].totals.totalPlacedDuration;
          totalSpotsCount += +this.wGRPmoddedTable.commercials[comm].totals.totalSpotsCount;
          totalSpotsCountPrime += +this.wGRPmoddedTable.commercials[comm].totals.spot_count_prime;
          totalSpotsCountOffPrime += +this.wGRPmoddedTable.commercials[comm].totals.spot_count_off_prime;
        }
        //general table totals
        this.wGRPmoddedTable.totals.totalFactWgrp = totalFactWgrp;
        this.wGRPmoddedTable.totals.totalPlanWgrp = totalPlanWgrp;
        this.wGRPmoddedTable.totals.totalFactPrimeWgrp = totalFactPrimeWgrp;
        this.wGRPmoddedTable.totals.totalPlanPrimeWgrp = totalPlanPrimeWgrp;
        this.wGRPmoddedTable.totals.totalFactOffPrimeWgrp = totalFactOffPrimeWgrp;
        this.wGRPmoddedTable.totals.totalPlanOffPrimeWgrp = totalPlanOffPrimeWgrp;
        this.wGRPmoddedTable.totals.totalPrice = totalPrice;
        this.wGRPmoddedTable.totals.totalPrimePrice = totalPrimePrice;
        this.wGRPmoddedTable.totals.totalOffPrimePrice = totalOffPrimePrice;
        this.wGRPmoddedTable.totals.totalPlacedDuration = totalPlacedDuration;
        this.wGRPmoddedTable.totals.totalSpotsCount = totalSpotsCount;
        this.wGRPmoddedTable.totals.totalSpotsCountPrime = totalSpotsCountPrime;
        this.wGRPmoddedTable.totals.totalSpotsCountOffPrime = totalSpotsCountOffPrime;
      }
    },
  },
};
</script>

<style lang="sass">
.wgrp-table__wrapper
  overflow-y: auto
  table#wgrps-table td:nth-child(n + 2),
  table#wgrps-table th:nth-child(n + 2)
    text-align: right
</style>
